<template>
  <div class='tenants-hero'>
    <div class="container">
      <h1>{{$t('rentTitle')}}</h1>
      <h2 class="tenants-hero__subtitle">{{$t('rentContent')}}</h2>
      <a href="/tenant-questionnaire" class="btn">{{$t('fillApplication')}}</a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TenantsHero'
  }
</script>